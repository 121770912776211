

const webp_imgs = [];
const webp_backs = [];

IMAGE_OBSERVER_CONFIG = { childList: true, subtree: true };

const mutation_callback = function(mutationsList, observer) {
    for(const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (let anode of mutation.addedNodes) {
          if (anode.tagName == 'IMG' && anode.src.endsWith(".webp")) {
            const osrc = anode.src
            webp_imgs.push(anode);
          } else {
            if (anode instanceof Element || anode instanceof HTMLDocument) {
              const nbimg = window.getComputedStyle(anode).getPropertyValue('background-image');
              if (nbimg.startsWith('url("') && nbimg.endsWith('.webp")')) {
                webp_backs.push({
                  node: anode,
                  bgstr: nbimg.slice(0, -6)+'png")'
                });
              }
            }
          }
        }
      }
    }
};



IMAGE_OBSERVER = new MutationObserver(mutation_callback);


const bulb_times = [
  { on: .3, off: 1 },
  { on: .1, off: .5 },
  { on: 1, off: .3 },
  { on: 5, off: .1 }

]
const max_off = .5, max_on = .1;

animate_bulbs = (bulbs_svg, id1, id2, id3, id4) => {
  const svg_doc = bulbs_svg.contentDocument;
  const upper_left_bulb = svg_doc.getElementById(id1);
  const upper_rigth_bulb = svg_doc.getElementById(id2);
  const center_bulb = svg_doc.getElementById(id3);
  const bottom_bulb = svg_doc.getElementById(id4);
  const bulbs = [
    upper_left_bulb,
    upper_rigth_bulb,
    center_bulb,
    bottom_bulb
  ];

  console.log("bulbs", bulbs);

  for (let b = 0; b < bulbs.length; b++) {
    const bulb = bulbs[b];
    bulb.classList.add("bulb");

    const blink_on_fn = () => {
    }

    const blink_off_fn = () => {
      bulb.classList.remove("blink");
    }

    const blink_fn = () => {
      bulb.style.opacity = 0;
      setTimeout(() => {
        bulb.style.opacity = 1;

        setTimeout(blink_fn, Math.random() * bulb_times[b].on * 1000);
      }, Math.random() * bulb_times[b].off * 1000);
    }


    blink_fn();
    
  }
}

window.addEventListener("load", async function (e) {
  try {

    let header_e = document.body.querySelector("header");
    let menu_itemz = document.getElementsByClassName("menu_item");
    let header_links = document.body.querySelector("div.header-links");
    let lang_menu = document.body.querySelector("div.lang_menu");

    let menu_button = document.getElementById("respmenu_switch");
    menu_button.addEventListener('click', function(e) {
      if (!header_e.classList.contains("display")) {
        menu_button.style.transform = "rotate(180deg)";
        header_e.classList.add("display");
      } else {
        menu_button.style.transform = "";
        header_e.classList.remove("display");
      }
    })

    const webp_support = await new Promise((out) => {
      let test_webp = document.createElement("img");
      test_webp.addEventListener("error", (evt) => {
        out(false)
      });
      test_webp.addEventListener("load", (evt) => {
        out(true)
      });
      test_webp.src = "/g/res/test-support.webp"
    });

    IMAGE_OBSERVER.disconnect();
    
    if (!webp_support) {
      for (let anode of webp_imgs) {
        await new Promise((out) => {
          const orig = anode.src;
          anode.src = anode.src.slice(0, -4)+"png";
          anode.addEventListener("load", (evt) => {
            console.log("LOADED", anode.src);
            out();
          });
        });
      }
  
      for (let bak of webp_backs) {
        bak.node.style.backgroundImage = bak.bgstr;
      }
    }

  } catch (err) {
    console.error(err);
  }
});



